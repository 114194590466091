import { useContext, useState, useEffect, useMemo } from 'react';
import UserContext from 'src/context/UserContext';

const useAccessPerView = (module) => {
  const [{ user }] = useContext(UserContext);
  const [accessRights, setAccessRights] = useState({
    view: false,
    edit: false,
    LEVEL1: false,
    LEVEL2: false,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user && module) {
      const rights = getAccesRightPerModule(module);
      setAccessRights(rights);
      setLoading(false);
    }
  }, [module, user]);

  const getAccesRightPerModule = (module) => {
    const { roleFeatureSets = [] } = user.role;
    const isSuperAdmin = ['SysAdmin', 'SuperAdmin'].includes(user.role.name);
    const rights = {
      view: false,
      edit: isSuperAdmin,
      LEVEL1: isSuperAdmin,
      LEVEL2: isSuperAdmin,
    };
    roleFeatureSets.forEach((feature) => {
      if (feature.featureSet.route === module) {
        switch (feature.accessRightId) {
          case 1:
            rights.view = true;
            break;
          case 2:
            rights.edit = true;
            break;
          case 3:
            rights.LEVEL1 = true;
            break;
          case 4:
            rights.LEVEL2 = true;
            break;
          default:
            return;
        }
      }
    });
    return rights;
  };

  return {
    isViewMode: accessRights.view,
    isEditMode: accessRights.edit,
    hasAccess: { LEVEL1: accessRights.LEVEL1, LEVEL2: accessRights.LEVEL2 },
    loading,
    getAccesRightPerModule,
  };
};

export default useAccessPerView;
