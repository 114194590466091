import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../services/config';

const initialState = {
  loading: false,
  error: null,
  workersList: null,
  selectedWorker: null,
  selectedWorkerProject: null,
  bulkLoading: false,
  selectedProject: null,
  workerHistoryList: null,
  workerHistoryLoading: false,
  jobSwitchingLoading: false,
};

const slice = createSlice({
  name: 'workers',
  initialState,
  reducers: {
    gettingWorkers(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingWorkersSuccess(state, action) {
      state.workersList = action.payload.data;
      state.loading = false;
      state.error = null;
      state.selectedWorkerProject = action.payload.projectId ?? null;
    },
    gettingWorkersFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    setWorker(state, action) {
      state.selectedWorker = action.payload;
    },
    editingWorker(state, action) {
      state.loading = true;
    },
    editingWorkerSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    editingWorkerFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    addingBulkWorker(state, action) {
      state.bulkLoading = true;
    },
    addingBulkWorkerSuccess(state, action) {
      state.bulkLoading = false;
      state.error = null;
    },
    addingBulkWorkerFailure(state, action) {
      state.bulkLoading = false;
      state.error = action.payload;
    },

    verifyinggWorker(state, action) {
      state.loading = true;
    },
    verifyinggWorkerSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    verifyinggWorkerFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    selectingProject(state, action) {
      state.selectedProject = action.payload;
    },

    gettingWorkerHistory(state, action) {
      state.workerHistoryLoading = true;
      state.error = null;
    },
    gettingWorkerHistorySuccess(state, action) {
      state.workerHistoryList = action.payload;
      state.workerHistoryLoading = false;
      state.error = null;
    },
    gettingWorkerHistoryFailure(state, action) {
      state.workerHistoryLoading = false;
      state.error = action.payload;
      state.workerHistoryList = null;
    },

    switchingWorkerJob(state, action) {
      state.jobSwitchingLoading = true;
      state.error = null;
    },
    switchingWorkerJobSuccess(state, action) {
      state.jobSwitchingLoading = false;
      state.error = null;
    },
    switchingWorkerJobFailure(state, action) {
      state.jobSwitchingLoading = false;
      state.error = action.payload;
      state.workerHistoryList = null;
    },
    markingWorker(state, action) {
      state.loading = true;
      state.error = null;
    },
    markingWorkerSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    markingWorkerFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
const {
  gettingWorkers,
  gettingWorkersSuccess,
  gettingWorkersFailure,

  setWorker,
  editingWorker,
  editingWorkerSuccess,
  editingWorkerFailure,
  verifyinggWorker,
  verifyinggWorkerSuccess,
  verifyinggWorkerFailure,
  addingBulkWorker,
  addingBulkWorkerSuccess,
  addingBulkWorkerFailure,
  selectingProject,
  gettingWorkerHistory,
  gettingWorkerHistorySuccess,
  gettingWorkerHistoryFailure,
  switchingWorkerJobFailure,
  switchingWorkerJobSuccess,
  switchingWorkerJob,
  markingWorker,
  markingWorkerSuccess,
  markingWorkerFailure,
} = slice.actions;
export const workersReducer = (state) => state.workers;

export const getWorkers =
  (projectId, createdBy = 0) =>
  async (dispatch) => {
    dispatch(gettingWorkers());
    if (!projectId) {
      try {
        const response = await axios.get(`${config.baseUrl}/dashboard/worker`);

        if (response.status === 200) {
          dispatch(gettingWorkersSuccess({ data: response.data }));
        }
        return response;
      } catch (e) {
        dispatch(
          gettingWorkersFailure('Something went wrong while getting users!')
        );
      }
      return;
    }
    try {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/worker?projectId=${projectId}&createdBy=${createdBy}`
      );

      if (response.status === 200) {
        dispatch(gettingWorkersSuccess({ data: response.data, projectId }));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingWorkersFailure('Something went wrong while getting users!')
      );
    }
  };

export const updateWorker =
  (worker, filesArray, workerId) => async (dispatch) => {
    dispatch(editingWorker());
    if (filesArray?.length > 0) {
      try {
        let filterEmptyFiles = filesArray?.filter((item) => {
          if (item.file) {
            return item;
          }
        });
        for (let index = 0; index <= filterEmptyFiles.length - 1; index++) {
          const item = filterEmptyFiles[index];
          let formData = new FormData();
          formData.append('file', item.file);
          let resp = await axios.post(
            `${config.baseUrl}/dashboard/worker/upload?workerId=${workerId}&document=${item.type}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          if (index === filterEmptyFiles.length - 1 && resp.status === 200) {
            const response = await axios.post(
              `${config.baseUrl}/dashboard/worker/addupdateworker`,
              worker
            );
            if (response.status === 200) {
              dispatch(editingWorkerSuccess(response.data));
            }
            return response;
          } else {
            dispatch(editingWorkerFailure('Error: Multimedia not updated!'));
          }
        }
      } catch (e) {
        dispatch(
          editingWorkerFailure('Something went wrong while getting users!')
        );
      }
    } else {
      try {
        const response = await axios.post(
          `${config.baseUrl}/dashboard/worker/addupdateworker`,
          worker
          //  headers: { "Content-Type": "multipart/form-data" },
        );

        if (response.status === 200) {
          dispatch(editingWorkerSuccess(response.data));
        }
        return response;
      } catch (e) {
        dispatch(
          editingWorkerFailure('Something went wrong while getting users!')
        );
      }
    }
  };
export const createWorker =
  (worker, adharCard, panCard, profilePicture) => async (dispatch) => {
    dispatch(editingWorker());
    try {
      const response = await axios.post(
        `${config.baseUrl}/dashboard/worker/addupdateworker`,
        worker,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        dispatch(editingWorkerSuccess(response.data));

        let workerId = response.data.workerId;
        // formData.append('panCard', panCard);
        [1, 2].map(async (item) => {
          if (item === 1 && adharCard) {
            let formData = new FormData();
            formData.append('file', adharCard);
            let resp = await axios.post(
              `${config.baseUrl}/dashboard/worker/upload?workerId=${workerId}&document=IdentityCard`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
          }
          // if (item === 2 && panCard) {
          //   let formData = new FormData();

          //   formData.append("file", panCard);
          //   let resp = await axios.post(
          //     `${config.baseUrl}/dashboard/worker/upload?workerId=${workerId}&document=PanCard`,
          //     formData,
          //     {
          //       headers: {
          //         "Content-Type": "multipart/form-data",
          //       },
          //     }
          //   );
          // }

          if (item === 2 && profilePicture) {
            // let formData = new FormData();
            // formData.append("file", profilePicture);

            let resp = await axios.post(
              `${config.baseUrl}/dashboard/worker/upload?workerId=${workerId}&document=ProfilePicture`,
              profilePicture,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
          }
        });
      }
      dispatch(
        editingWorkerFailure('Something went wrong while getting users!')
      );
      return response;
    } catch (e) {
      dispatch(
        editingWorkerFailure('Something went wrong while getting users!')
      );
    }
  };

export const verifyWorker = (workerId) => async (dispatch) => {
  dispatch(verifyinggWorker());
  try {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/worker/verify?workerId=${workerId}`

      //  headers: { "Content-Type": "multipart/form-data" },
    );

    if (response.status === 200) {
      dispatch(verifyinggWorkerSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      verifyinggWorkerFailure('Something went wrong while verifying worker!')
    );
  }
};

export const buldAddWorkers = (data) => async (dispatch) => {
  dispatch(addingBulkWorker());

  try {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/worker/bulkadd`,
      data
    );

    if (response.status === 200) {
      dispatch(addingBulkWorkerSuccess());
    } else {
      dispatch(
        addingBulkWorkerFailure(
          'Something went wrong while sending bulk workers!'
        )
      );
    }
    return response;
  } catch (e) {
    dispatch(
      addingBulkWorkerFailure(
        'Something went wrong while sending bulk workers!'
      )
    );
  }
};

export const setWorkerForDetail = (worker) => (dispatch) => {
  dispatch(setWorker(worker));
};
export const selectAProject = (project) => async (dispatch) => {
  dispatch(selectingProject(project));
};

export const getWorkerHistory = (workerId) => async (dispatch) => {
  dispatch(gettingWorkerHistory());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/worker/${workerId}`
    );
    if (response.status === 200) {
      dispatch(gettingWorkerHistorySuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingWorkerHistoryFailure(
        'Something went wrong while getting worker detail!'
      )
    );
  }
};

export const clearWorkerHistory = () => async (dispatch) =>
  dispatch(gettingWorkerHistorySuccess(null));

export const switchWorkerJob = (data) => async (dispatch) => {
  dispatch(switchingWorkerJob());
  try {
    const response = await axios.put(
      `${config.baseUrl}/dashboard/Job/switch-worker-job`,
      data
    );
    if (response.status === 200) {
      dispatch(switchingWorkerJobSuccess());
    }
    if (response.status === 404) {
      dispatch(switchingWorkerJobFailure(response.data.error));
    }
    return response;
  } catch (e) {
    dispatch(
      switchingWorkerJobFailure(
        'Something went wrong while marking worker job!'
      )
    );
  }
};

export const markWorkerJob = (data) => async (dispatch) => {
  dispatch(markingWorker());
  try {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/Job/delist`,
      data
    );
    if (response.status === 200) {
      dispatch(markingWorkerSuccess());
    }
    return response;
  } catch (e) {
    dispatch(
      markingWorkerFailure('Something went wrong while marking worker job!')
    );
  }
};

export default slice.reducer;
